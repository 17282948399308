<template>
  <div>
    <van-cell title="合同管理" is-link @click="toContractList" />
    <van-cell title="课时账户" is-link @click="toClassHourList" />
    <van-cell title="上课记录" is-link @click="toLessonLogList" />
    <van-cell title="签到签退记录" is-link @click="toSignInOutList" />

    <div class="btn-wrapper">
      <van-button block type="primary" @click="toBoundStudent">绑定学员信息</van-button>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      appId: -2,
      msg: '学员信息主页'
    }
  },
  created() {
    this.appId = this.$route.params.appId
  },
  mounted() {},
  methods: {
    toBoundStudent() {
      this.$router.push(`/${this.appId}/guardian/boundStudent`)
    },
    toContractList() {
      this.$router.push(`/${this.appId}/guardian/contract/list`)
    },
    toClassHourList() {
      this.$router.push(`/${this.appId}/guardian/classHour/list`)
    },
    toLessonLogList() {
      this.$router.push(`/${this.appId}/guardian/lessonLog/list`)
    },
    toSignInOutList() {
      this.$router.push(`/${this.appId}/guardian/signInOut/list`)
    },
    initData() {
      console.log('initData')
    }
  }
}
</script>
<style lang="less" scoped>
.btn-wrapper {
  padding: 0.5rem;
}
</style>
